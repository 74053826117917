<template>
  <v-row>
    <v-col class="mx-2" cols="12" lg="6" xl="4">
      <p class="font-weight-bold">Catégories</p>
      <v-select :rules="[notNullNorEmpty]" :items="types" item-value="type" item-text="name" label="Type" v-model="type"></v-select>
      <v-text-field :rules="[notNullNorEmpty]" label="Nom de la catégorie" v-model="name"></v-text-field>
      <v-btn color="primary" @click="createCategory" v-if="isResponsableOrAdmin || hasPermission('add_category')">Créer</v-btn>
      <v-list>
        <v-list-item v-for="categorie in categories" :key="categorie.id">
          <v-list-item-title>{{categorie.name}}</v-list-item-title>
          <v-list-item-action>
            <v-btn text class="pa-0" v-if="isResponsableOrAdmin || hasPermission('delete_category')" @click="deleteCategory(categorie)">
              <v-icon color="red">mdi-trash-can-outline</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-list>
    </v-col>
  </v-row>
</template>

<script>
import {AuthMixin} from "@/mixins/util/AuthMixin";

let type_select = [{
        name: "Domaine",
        type: "domaine"
      },
        {
          name: "Métier",
          type: "metier"
        }
      ]
export default {
  name: "Categories",
  mixins: [AuthMixin],
  mounted() {
    this.$store.dispatch("categories/fetchCategories");
  },
  data() {
    return {
      types: type_select,
      type: null,
      name: null,
    }
  },
  computed: {
    categories() {
      let categories = this.$store.getters["categories/allCategories"];
      if(this.type !== null) {
        return categories.filter(item => item.type === this.type);
      } else {
        return categories;
      }
    }
  },
  methods: {
    async createCategory() {
      if(this.type === null || this.name === null) {
        await this.$store.dispatch("messages/setAnnounce", {
          "type": "warning",
          "message": "Certains champs ne sont pas remplis."
        });
        return;
      }
      let category = {
        type: this.type,
        name: this.name,
      }
      try{
        await this.$store.dispatch("categories/postCategory", category);
      } catch (e) {
        await this.$store.dispatch("messages/setAnnounce", {
          "type": "error",
          "message": "Une erreur est survenue. La catégorie n'a pas pu être créée."
        });
      }
    },
    async deleteCategory(category) {
      try {
        await this.$store.dispatch("categories/deleteCategory", category);
        await this.$store.dispatch("messages/setAnnounce", {
          "type": "success",
          "message": "Catégorie supprimée."
        });
      } catch (e) {
        await this.$store.dispatch("messages/setAnnounce", {
          "type": "error",
          "message": "Une erreur est survenue. La catégorie n'a pas pu être supprimée."
        });
      }
    },
    notNullNorEmpty(value) {
      return value !== null && value !== "" || "Ce champ n'a pas été rempli.";
    }
  },
}
</script>

<style scoped>

</style>