<template>
    <v-container fluid class="pa-0">
    <v-toolbar>
      <v-slide-group>
        <v-slide-item class="mt-4 mr-2">
          <v-btn @click="$emit('switch-drawer')" color="primary">Nouvel utilisateur</v-btn>
        </v-slide-item>
        <v-slide-item class="mt-4 mx-2">
          <v-text-field label="Nom" v-model="search_name"></v-text-field>
        </v-slide-item>
        <v-slide-item class="mt-4 mx-2">
          <v-select label="Groupe" item-text="name" item-value="name" :items="groups" v-model="filter_group"></v-select>
        </v-slide-item>
        <v-slide-item class="mt-4 mx-2">
          <v-btn @click="resetFilter" text color="red"><v-icon color="red">mdi-cancel</v-icon> Retirer</v-btn>
        </v-slide-item>

      </v-slide-group>
    </v-toolbar>
    <v-row class="ma-0">
      <v-col order-md="1" order="2" cols="12" md="5" id="user-list">
        <v-tabs>
          <v-tab  class="orange--text">
            Tous les utilisateurs
          </v-tab>
          <v-tab-item>
            <UserList/>
          </v-tab-item>
          <v-tab>
            Responsables techniques
          </v-tab>
          <v-tabs-slider :color="tabColor"></v-tabs-slider>
          <v-tab-item>
            <RPList />
          </v-tab-item>
          <v-tab>
            Collaborateurs
          </v-tab>
          <v-tab-item>
            <CollaborateurList/>
          </v-tab-item>
          <v-tab  class="secondary--text">
            Demandeurs
          </v-tab>
          <v-tab-item>
            <DemandeurList/>
          </v-tab-item>
        </v-tabs>

      </v-col>
      <v-col order-md="2" order="1" md="7" cols="12" id="intervenant-view">
        <UserView v-on="$listeners" />
      </v-col>
    </v-row>

  </v-container>

</template>

<script>
import UserView from "@/components/admin/UserView";
import RPList from "@/components/admin/RPList";
import DemandeurList from "@/components/admin/DemandeurList";
import UserList from "@/components/admin/UserList";
import CollaborateurList from "@/components/intervenants/CollaborateurList";

export default {
  name: "Intervenants",
  components: {CollaborateurList, DemandeurList, RPList, UserView, UserList},
  mounted() {
    if(this.$store.getters["profile/profiles"].length === 0) {
      this.$store.dispatch("profile/fetchProfiles");
    }
    this.$store.commit("profile/setSelectedProfile", null);
  },
  computed: {
    selected_intervenant() {
      return this.$store.getters["profile/getSelectedProfile"];
    },
    groups() {
      return this.$store.getters["groups/groups"];
    },
    search_name: {
      get() {
        return this.$store.getters["profile/getSearchName"];
      },
      set(value) {
        return this.$store.commit("profile/setSearchName", value)
      }
    },
    filter_group: {
      get() {
        return this.$store.getters["profile/getFilterGroup"];
      },
      set(value) {
        return this.$store.commit("profile/setFilterGroup", value)
      }
    },
  },
  data() {
    return {
      dialog: false,
      tabColor: "primary",
    }
  },
  methods: {
    lastTabClicked(tab) {
      this.tabColor = tab === "prestataire" ? "primary" : "secondary";
    },
    resetFilter() {
      this.filter_group = null;
      this.search_name = null;
    }
  }
}
</script>

