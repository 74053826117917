<template>
  <v-card class="pa-2">
    <v-row>
      <v-col cols="12" sm="10" md="8" lg="6" xl="4">
        <v-row no-gutters>
          <v-col v-if="isResponsableOrAdmin || hasPermission('change_config')" offset="9" cols="3">
            <v-btn v-if="!edit" @click="edit = true" text color="primary">
              <v-icon>mdi-pencil</v-icon>
              Modifier
            </v-btn>
            <v-btn v-else @click="edit = false" text color="primary">
              <v-icon>mdi-arrow-left</v-icon>
              Retour
            </v-btn>
          </v-col>
          <v-col class="my-1" cols="12">
            <h4>Logo de la société</h4>
            <v-file-input v-if="edit" v-model="image" label="Image"></v-file-input>
            <a v-else-if="societyLogo" :href="societyLogo.file" target="_blank">Voir le logo</a>
            <span class="font-italic" v-else>Non défini</span>
          </v-col>

          <v-col class="my-1" cols="12">
            <h4>Nom de la société</h4>
            <v-text-field v-if="edit" v-model="name" label="Société"></v-text-field>
            <span v-else-if="societyName">{{societyName.value}}</span>
            <span v-else class="font-italic">Non défini</span>
          </v-col>

          <v-col class="my-1" cols="12">
            <h4>Code du site</h4>
            <v-text-field v-if="edit && isResponsableOrAdmin" v-model="code" label="Code du site"></v-text-field>
            <span v-else-if="siteCode">{{siteCode.value}}</span>
            <span v-else class="font-italic">Non défini</span>
          </v-col>
          <v-col class="mt-2" cols="12">
            <h4 class="grey--text mb-0">Affichage</h4>
            <v-divider></v-divider>
          </v-col>
          <v-col class="my-1" cols="12">
            <h4>Toujours afficher les statistiques du tableau de bord</h4>
            <v-checkbox @change="encartChanged = true" v-if="edit && isResponsableOrAdmin" v-model="showHomePageCards" label="Afficher les encarts"></v-checkbox>
            <span v-else-if="displayCards != null && displayCards.value === 'true'">Oui</span>
            <span v-else-if="displayCards != null && displayCards.value === 'false'">Non</span>
            <span v-else class="font-italic">Non défini</span>
          </v-col>

          <v-col class="my-1" cols="12">
            <v-btn class="primary" @click="updateConfig" v-if="edit">Enregistrer</v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-card>
</template>
<script>
import {AuthMixin} from "@/mixins/util/AuthMixin";

export default {
  mixins: [AuthMixin],
  name: 'General',
  data() {
    return {
      edit: false,
      image: null,
      name: null,
      code: null,
      showHomePageCards: null,
      encartChanged: false,
    }
  },
  async mounted() {
    if (this.$store.getters["config/allConfigs"].length === 0) {
      await this.$store.dispatch("config/fetchConfigs");
    }
    this.showHomePageCards = this.displayCards.value === 'true';
  },
  computed: {
    societyLogo() {
      return this.$store.getters["config/societyLogo"];
    },
    societyName() {
      return this.$store.getters["config/societyName"];
    },
    siteCode() {
      return this.$store.getters["config/siteCode"];
    },
    displayCards() {
      return this.$store.getters["config/displayCards"];
    },
  },
  methods: {
    resetFields() {
      this.name = null;
      this.image = null;
      this.edit = false;
    },
    async updateConfig() {
      if (this.name !== null) {
        await this.$store.dispatch("config/postConfig", {key: "SOCIETY_NAME", value: this.name});
      }
      if (this.image !== null) {
        await this.$store.dispatch("config/postConfig", {key: "SOCIETY_LOGO", file: this.image});
      }
      if (this.code !== null) {
        await this.$store.dispatch("config/postConfig", {key: "SITE_CODE", value: this.code});
      }
      if (this.showHomePageCards !== null && this.encartChanged) {
        await this.$store.dispatch("config/postConfig", {key: "DISPLAY_CARDS", value: this.showHomePageCards.toString()});
      }
      this.resetFields();
    }
  }
}
</script>
